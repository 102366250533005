<template>
    <div>
        <div style="display: inline-block; width: 100%; height: 100%;">
            <div class="child-top">
                <el-date-picker
                  v-model="date"
                  type="daterange"
                  align="right"
                  unlink-panels
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="create(1)"
                  class="picker_date"
                ></el-date-picker>
            </div>
            <div class="content-flex-pages">
                <el-table :empty-text="tableData.length ? '' : '暂无数据'" :data="tableData" @selection-change="tableSelectionChange" class="content-test-table" style="width: 100%;" v-loading="tableLoading">
                    <el-table-column type="selection" width="45"></el-table-column>
                    <el-table-column label="序号" type="index" width="45">
                        <template slot-scope="scope">
                            {{ (currentPage - 1) * 10 + scope.$index + 1 }}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作日期" prop="operate_time" show-overflow-tooltip></el-table-column>
                    <el-table-column label="操作类型" prop="type_name" show-overflow-tooltip align="center"></el-table-column>
                    <el-table-column label="操作状态" prop="result_name" show-overflow-tooltip align="center"></el-table-column>
                    <el-table-column label="操作终端" prop="terminal_name" show-overflow-tooltip align="center"></el-table-column>
                    <el-table-column label="IP地址" prop="operator_ip" show-overflow-tooltip></el-table-column>
                    <el-table-column label="登录账号" prop="operator_account" show-overflow-tooltip align="center"></el-table-column>
                    <el-table-column label="登录人姓名" prop="operator_name" show-overflow-tooltip align="center"></el-table-column>
                    <!-- <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                        </template>
                    </el-table-column> -->
                </el-table>
                <el-pagination :current-page.sync="currentPage" :page-size="10" :total="tableTotal" @current-change="handleCurrentChange" class="table-pagination" layout="total, prev, pager, next , jumper"></el-pagination>
            </div>
        </div>
        <!--  新增-->
        <el-dialog :close-on-click-modal="false" :visible.sync="dialogTableVisible" :before-close="cancel" :title="!dialogTableVisibleType ? '新增' : '编辑'" width="30%" v-loading="loading" center>
            <div class="ModifyDoalog">
                <el-form :label-position="'left'" :model="form" :rules="rules" label-width="90px" ref="form">
                    <el-form-item label="所属类别" prop="department">
                        <el-select v-model="form.department" placeholder="请选择" style="width: 100%;" clearable>
                            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="父级" prop="parentName">
                        <el-cascader v-model="form.parentName" :options="parentData" style="width: 100%;" clearable :props="{
                                checkStrictly: true,
                                value: 'uuid',
                                label: 'name',
                                emitPath: false,
                            }"></el-cascader>
                    </el-form-item>
                    <el-form-item label="名称" prop="name">
                        <el-input v-model="form.name" clearable placeholder="请输入名称"></el-input>
                    </el-form-item>
                    <el-form-item label="接口地址" prop="uri">
                        <el-input v-model="form.uri" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="接口地址过滤" prop="uriFilter">
                        <el-input v-model="form.uriFilter" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="顺序" prop="order">
                        <el-input v-model="form.order" clearable placeholder="填写顺序数字"></el-input>
                    </el-form-item>
                    <el-form-item label="前缀字符" prop="prefix">
                        <el-input v-model="form.prefix" clearable placeholder="请输入前缀字符"></el-input>
                    </el-form-item>
                    <el-form-item label="图标" prop="icon">
                        <el-input v-model="form.icon" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="路由" prop="router">
                        <el-input v-model="form.router" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="按钮类型" prop="btnType">
                        <el-input v-model="form.btnType" clearable placeholder="1:列表2:查3:增4:改5:删6:下载7:导入8:导出9:上传10:打印11:启/停用"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="form-bottom-button">
                <el-button @click="save" type="primary" v-no-more-click class="save_btn" round>保存</el-button>
                <el-button @click="cancel" class="cancel_btn" round>取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            input4: '',
            roleState: '',
            tableData: [{
                name: '',
                type: '3',
                uri: '/p/nutrition/nutrition_read',
                order_no: '0',
            }, {uuid:0}],
            currentPage: 1,
            tableTotal: 0,
            dialogTableVisible: false,
            tableDataSelectArr: [],
            dialogTableVisibleType: false,
            tableLoading: false,
            // tableLoading: true, //loading
            rules: {
                department: { ...this.$rules.noEmpty, message: '请选择所属类别' },
                name: { ...this.$rules.noEmpty, message: '名称不能为空' },
            },
            typeOptions: [
                // 1:模块 2:菜单 3:按钮
                {
                    value: 1,
                    label: '模块',
                },
                {
                    value: 2,
                    label: '菜单',
                },
                {
                    value: 3,
                    label: '按钮',
                },
            ],
            parentData: [],
            form: {
                department: null,
                parentName: '0',
                name: '',
                uri: '',
                uriFilter: '',
                order: '',
                prefix: '',
                icon: '',
                router: '',
                btnType: '',
                id: '',
            },
            loading: false,
            numType: false,
            date: []
        };
    },
    mounted() {
        this.create();
    },
    methods: {
        create(pages) {
            this.tableLoading = true;
            if (pages) {
                this.currentPage = pages;
            } else {
                this.currentPage = 1;
            }
            // 列表数据
            this.$axios
                .post(
                    'p/userOperation/lists',
                    this.$qs({
                        page: this.currentPage,
                        pagesize: 10,
                        start_date: this.date ? this.date[0] : '',
                        end_date: this.date ? this.date[1] : ''
                    })
                )
                .then((res) => {
                  console.log(res.data)
                    if (res.data.code === 0) {
                        this.tableData = res.data.data.rows;
                        this.tableTotal = res.data.data.total;
                    } else {
                        this.$utils.message({
                            type: 'error',
                            message: res.data.message,
                        });
                    }
                    this.tableLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        // 关键词搜素
        getData() {
            this.create();
        },
        // 新增
        addDataLog() {
            this.dialogTableVisible = true;
            this.dialogTableVisibleType = false;
            this.getparentAll();
            this.form.id = '';
        },
        // 获取父级数据
        getparentAll() {
            this.$axios
                .get('/p/menu/getAll')
                .then((res) => {
                    if (res.data.code === 0) {
                        this.parentData = res.data.data;
                    } else {
                        this.$utils.message({
                            type: 'error',
                            message: res.data.message,
                        });
                    }
                    this.tableLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        // 单行删除
        delTableData(row, index) {
            this.$confirm('确定删除数据吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true,
            }).then(() => {
                this.$axios
                    .post(
                        '/p/menu/del',
                        this.$qs({
                            uuid: row.uuid,
                        })
                    )
                    .then((res) => {
                        if (res.data.code === 0) {
                            this.$message({
                                type: 'success',
                                message: res.data.message,
                            });
                            let num = this.getNum(this.tableTotal - 1);
                            console.log(this.currentPage, num);
                            if (this.currentPage > num) {
                                this.currentPage = num;
                            }
                            this.create(this.currentPage);
                        } else {
                            this.$utils.message({
                                type: 'error',
                                message: res.data.message,
                            });
                        }
                        this.tableLoading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            });
        },
        // 编辑
        editorData(row) {
            this.loading = true;
            this.dialogTableVisible = true;
            this.dialogTableVisibleType = true;
            this.getparentAll();
            this.$axios
                .post(
                    '/p/menu/read',
                    this.$qs({
                        uuid: row.uuid,
                    })
                )
                .then((res) => {
                    if (res.data.code === 0) {
                        var data = res.data.data;
                        this.form.id = data.uuid;
                        this.form.department = data.type;
                        this.form.parentName = data.puuid;
                        this.form.name = data.name;
                        this.form.uri = data.uri;
                        this.form.uriFilter = data.filter;
                        this.form.order = data.order_no;
                        this.form.prefix = data.prefix;
                        this.form.icon = data.icon;
                        this.form.router = data.link;
                        this.form.btnType = data.btn_type;
                    } else {
                        this.$utils.message({
                            type: 'error',
                            message: res.data.message,
                        });
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.create(val);
            this.tableLoading = true;
        },
        // 状态改变
        ChangeState(row) {
            this.$axios
                .post(
                    '/p/menu/able',
                    this.$qs({
                        uuid: row.uuid,
                    })
                )
                .then((res) => {
                    if (res.data.code === 0) {
                        this.create(this.currentPage);
                        this.$message({
                            type: 'success',
                            message: res.data.message,
                        });
                    } else {
                        this.$utils.message({
                            type: 'error',
                            message: res.data.message,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        // 选择状态
        stateChange() {
            this.create();
        },
        // 新增/编辑 保存
        save() {
            this.tableLoading = true;
            var data = {
                type: this.form.department,
                puuid: this.form.parentName !== '' ? this.form.parentName : '0',
                name: this.form.name,
                uri: this.form.uri,
                filter: this.form.uriFilter,
                btn_type: this.form.btnType,
                prefix: this.form.prefix,
                icon: this.form.icon,
                link: this.form.router,
                order_no: this.form.order,
            };
            if (this.form.id !== '') {
                data.uuid = this.form.id;
            }
            this.$axios
                .post(
                    this.form.id !== '' ? '/p/menu/update' : '/p/menu/add',
                    this.$qs(data)
                )
                .then((res) => {
                    if (res.data.code === 0) {
                        this.dialogTableVisible = false;
                        this.$refs.form.resetFields();
                        this.create(this.currentPage);
                        this.$message({
                            type: 'success',
                            message: res.data.message,
                        });
                    } else {
                        this.$utils.message({
                            type: 'error',
                            message: res.data.message,
                        });
                    }
                    this.tableLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        // 新增/编辑 取消
        cancel() {
            this.dialogTableVisible = false;
            this.$refs.form.resetFields();
        },
        // 列表选框
        tableSelectionChange() {},
        // 查看当前页数
        getNum(data) {
            var num = data / 10,
                pages = '';
            if (num > Math.floor(num)) {
                pages = Math.ceil(num);
            } else {
                pages = num;
            }
            return pages;
        },
    },
};
</script>
<style lang="scss" scoped>
.content-flex-pages{
  background: #fff;
}
.child-top {
    .input-from {
        float: left;
        min-width: 850px;
        margin-bottom: 0;

        .searchBtn {
          margin-top: 20px;
        }

        &>.el-select {
            width: 150px;
            float: left;
            margin-top: 20px;

            .el-input__inner {
                border-radius: 0;
                border-right: transparent;
            }

            .el-input__inner:focus {
                border-right: 1px solid #409eff;
            }
        }

        .el-select,
        .el-input {
            margin-top: 20px;
        }

        .el-select:nth-child(4) {
            .el-input__inner:focus {
                border-right: 0.052083rem solid #409eff;
            }
        }

        .el-select:nth-child(5) {
            .el-input__inner {
                border-right: 0;
            }

            .el-input__inner:focus {
                border-right: 0.052083rem solid #409eff;
            }
        }

        .el-select:nth-child(6) {
            width: 120px;
            margin-left: 20px;

            .el-input__inner {
                border-radius: 0px 4px 4px 0;
            }
        }

        .el-select:nth-child(1) {
            .el-input__inner {
                border-radius: 4px 0 0 4px;
            }
        }

        .el-input.el-input--suffix.left-child-input {
            float: left;
            width: 200px;

            .el-input__inner {
                border-radius: 0 4px 4px 0;
            }
        }
    }

    .operation-button {
        // float: left;
        margin-bottom: 10px;

        .el-button {
            margin-right: 0;
        }
    }
}

.ModifyDoalog {
    max-height: 530px;
    margin-top: -20px;
    padding: 0 0 0 20px;
    overflow: auto;

    &>p {
        font-size: 20px;
        color: #3b3b3b;
        text-align: center;
        margin-bottom: 20px;
    }

    .el-form {
        padding-right: 30px;
        // max-height: 500px;
        // overflow: auto;
    }
}

.table-pagination {
    text-align: right;
    margin-top: 10px;

    /deep/ span {
        float: left;
    }
}

/deep/ input::-webkit-input-placeholder {
    color: #999;
}

/deep/ input::-moz-input-placeholder {
    color: #999;
}

/deep/ input::-ms-input-placeholder {
    color: #999;
}

.el-pagination{
  background: #fff;
  padding: 10px;
  padding-bottom: 10px;
  margin-top: 0;
}
</style>